<template>
  <div class="webPie">
    <div class="webPie-title">
      <span class="subtitle"
        ><span class="leftCircle" :style="{ background: data.color }"></span>{{ data.title
        }}<span class="rightCircle" :style="{ background: data.color }"></span
      ></span>
    </div>
    <div class="webPie-content" :id="id"></div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    id: String,
    data: Object
  },
  data() {
    return {
      dataSource: this.data,
      myChart: undefined
    }
  },
  watch: {
    data: {
      deep: true, // 深度监听
      handler: function (newVal) {
        this.dataSource = newVal
        const option = this.myChart.getOption()
        option.series[0].data = this.dataSource.data
        /*       option.series[1].data = this.dataSource.value
        option.series[0].data = this.setData()*/
        this.myChart.setOption(option)
      }
    }
  },
  methods: {
    setChart() {
      let option = {
        tooltip: {
          trigger: 'item',
          backgroundColor: '#11367a',
          textStyle: {
            color: '#6dd0e3',
            fontSize: 10
          },
          formatter: '{b}:{d}%'
        },
        series: [
          {
            type: 'pie',
            radius: '70%',
            top: '10%',
            center: ['50%', '30%'],
            label: {
              color: '#75deef',
              fontSize: 12,
              formatter: function (data) {
                return data.data.name + ' ' + data.percent.toFixed(0) + '%'
              }
            },
            labelLine: {
              length: 10,
              length2: 8,
              lineStyle: {
                color: 'rgb(57,63,90)'
              }
            },
            data: this.data.data
          }
        ]
      }
      this.myChart = this.$echarts(document.getElementById(this.id))

      this.myChart.clear()
      this.myChart.resize({
        width: document.getElementById(this.id).offsetWidth,
        height: document.getElementById(this.id).offsetHeight
      })

      this.myChart.setOption(option)
    }
  },
  mounted() {
    this.setChart()
  }
}
</script>

<style lang="less" scoped>
.webPie {
  width: 100%;
  height: 100%;

  .webPie-title {
    height: 10%;
    text-align: center;
    // line-height: 220px;

    .subtitle {
      color: #68c6d6;
      position: relative;
      font-size: 14px;

      .leftCircle {
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        left: -15px;
        top: 5px;
      }

      .rightCircle {
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        right: -15px;
        top: 5px;
      }
    }
  }

  .webPie-content {
    // margin-top: 1rem;
    // width: 300px;
    height: 90%;
  }
}
</style>
